<template>
  <!-- counter -->
  <div>
    <v-container grid-list-lg class="carder" fluid>
      <div>
        <!-- <div class="flex justify-center ma-3">
          <v-badge avatar bordered bottom overlap color="grey darken-1">
            <template v-slot:badge>
              <v-avatar>
                <v-icon color="white">mdi-plus</v-icon>
              </v-avatar>
            </template>

            <v-avatar size="100">
              <v-img src="https://cdn.vuetifyjs.com/images/lists/2.jpg"></v-img>
            </v-avatar>
          </v-badge>
        </div> -->
      </div>
      <v-tabs
        centered
        color="blue darken-1"
        background-color="transparent"
        grow
      >
        <v-tab
          v-for="(value, index) in navigation"
          :key="index"
          :to="value.path"
          >{{ value.title }}</v-tab
        >
      </v-tabs>
      <div v-for="(value, index) in navigation" :key="index" class="min-h">
        <div v-if="Route === value.name">
          <component :is="value.component" />
        </div>
      </div>
    </v-container>
  </div>
</template>
<script>
export default {
  name: "OrderSummary",

  data() {
    return {
      navigation: [
        {
          name: "profile",
          path: "/impersonate/account/profile",
          title: "Profile",
          component: () => import("./components/Profile"),
        },
        {
          name: "subscribe",
          path: "/impersonate/account/subscribe",
          title: "Subscription",
          component: () => import("./components/Subscription"),
        },
        {
          name: "slurp",
          path: "/impersonate/account/slurp",
          title: "Slurp",
          component: () => import("./components/Slurp"),
        },
        {
          name: "payment",
          path: "/impersonate/account/payment",
          title: "Payment",
          component: () => import("./components/Payment"),
        },
        // {
        //   name: "outlets",
        //   path: "/impersonate/overall/outlets",
        //   title: "Outlets",
        //   component: () => import("./components/Outlets"),
        // },
      ],
      Route: this.$route.params.param,
      // {
      //   name: "Melissa",
      //   store: "Miko cafe",
      // },
    };
  },

  watch: {
    $route: "updateRoute",
  },

  methods: {
    updateRoute() {
      this.Route = this.$route.params.param;
    },
  },
};
</script>

<style lang="scss" scoped>
.v-sheet--offset {
  top: -24px;
  position: relative;
}

.pad {
  margin-top: 3px;
}
.min-h {
  max-height: 84vh;
  overflow-y: auto;
}
.w-75 {
  width: 75%;
}
</style>
